(function(){

	var botaoTopo = $('[data-gototop="true"]');

	var alvosAnimacao = $('body,html');

	var topo = $('.topo');

	var alturaTopo = topo.innerHeight() - 27;

	botaoTopo.click(function(){

		alvosAnimacao.animate({
			scrollTop:0,
		},200, function(){
			botaoTopo.blur();
		});

	});

	$(window).scroll(function(){

		if($(this).scrollTop() > alturaTopo){
			botaoTopo.addClass('fixo');
		}else{
			botaoTopo.removeClass('fixo');
		}

	});



})();